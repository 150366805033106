import { useContext, useEffect, useState } from 'react';
import { ReactSortable } from "react-sortablejs";
import Dropzone from 'react-dropzone';
import pluralize from 'pluralize';
import { map, reject, curry, concat} from 'lodash/fp';

import { preventDefault } from 'shared/events';
import flash from 'shared/flash';

import ListingContext from '@/contexts/ListingContext';

import CropModal from './CropModal';

const MAX_IMAGES = 8;
const IS_NUMERIC = /^\d+$/;

const push = curry((value, array) => _.isArrayLike(array) ? concat(array, [value]) : [value]);

const updateImage = (id, update) => (img) => img.id === id ?
  {...img, ...update} :
  img;

const uploadMoreMessage = (count) => count < MAX_IMAGES ?
  `Upload up to ${MAX_IMAGES - count} more ${pluralize('image', MAX_IMAGES - count)}` :
  `Your listing contains the maximum number of images (${MAX_IMAGES})`;

export default function MediaUpload({
  images:value,

  cover_image_id='',
  label,
  required,
  modalImages,
  published,
  error:errors=[]
}) {
  const [displayCropModal, setDisplayCropModal] = useState(false);
  const [saveStatus, setSaveStatus] = useState();
  const [images, setImages] = useState(value);
  const [uploadingImages, setUploadingImages] = useState(false);

  const {uploadImageCrop, deleteAttachment, uploadAttachments, updateField, updateImageOrder} = useContext(ListingContext);

  useEffect(() => {
    if (saveStatus) {
      flash(...saveStatus);
      setSaveStatus(null)
    }
  }, [saveStatus]);

  useEffect(() => { setImages(value) }, [value]);

  const onDrop = async (acceptedImages) => {
    _.each(acceptedImages, addAndModifyImages);

    try {
      await uploadAttachments('images', acceptedImages);
      setSaveStatus(['success', 'Uploaded Successfully']);
    } catch(e) {
      setSaveStatus(['error', 'Uploaded failed']);
      _.each(images, (image) => {
        if (!IS_NUMERIC.test(image.id)) URL.revokeObjectURL(image.id);
      });
    }
    setUploadingImages(false)
  };

  const addAndModifyImages = (file) => {
    const id = _.uniqueId();
    addImage({id, uploading: true});

    const reader = new FileReader();
    reader.onload = ({target}) => {
      const url = target.result;
      setImages(map(updateImage(id, {url})));
    };
    reader.onerror = (error) => {
      setImages(reject({id}));
      alert("Unable to upload file. Please use a different image.");
      window.Rollbar && Rollbar.error("File Reader failed to load image", error);
    }
    reader.readAsDataURL(file);
  };

  const addImage = (image) => {
    setImages(push(image));
    setUploadingImages(true);
  };

  const updateCroppedImage = async (file, pixelCrop) => {
    let id = cover_image_id;

    setImages(map((image) => {
      if (image.id !== id) return image;
      return {
        url: file.preview,
        uploading: true,
        id: file.preview
      };
    }));
    setUploadingImages(true);
    setDisplayCropModal(false);
    await uploadImageCrop(cover_image_id, pixelCrop);
    setUploadingImages(false)
  };

  const removeMedia = async (id) => {
    try {
      await deleteAttachment('images', id);
    } catch(e) {
      console.warn(e);
      saveStatus(['error', 'Unable to delete image']);
    }
  };

  const toggleCropModal = () => setDisplayCropModal((x) => !x);

  const requiredField = required ? '*' : '';
  const lastPublishedPhoto = published && _.size(images) == 1;

  const coverImage = _.find(images, {id: cover_image_id});
  const modalCoverImage = _.find(modalImages, {id: cover_image_id});
  const imagePresence = coverImage || modalCoverImage;

  return (
    <div className={classnames("form-group", {error: errors.length})}>
      <label>{label} {requiredField}</label>
      {errors.length > 0 && <div className="error-message">Upload at least 1 image</div>}
      <Dropzone className="dropzone d-flex justify-content-center"
        onDrop={onDrop}
        disabled={uploadingImages || _.size(images) >= MAX_IMAGES}
        accept="image/*"
      >
        <button type="button" className="btn btn-primary mr-2 px-4" disabled={uploadingImages || _.size(images) >= MAX_IMAGES}>
          Upload Images
        </button>
        <span className="d-only-lg-xl mt-2">or drag files here.</span>
      </Dropzone>
      <div className="file-preview">
        <div className="card-row">
          <div className="file-count">
            {uploadingImages ? "Uploading images." : uploadMoreMessage(_.size(images))}
          </div>

          <ReactSortable
            list={images}
            setList={setImages}
            className="sortable"
            onSort={() => updateImageOrder(_.map(images, (i) => i.id))}
          >
            {_.map(images, ({id, url, uploading=false}) => (
              <div className="card-column-photo" key={url || id}>
                <div className={classnames('card', 'bg-white', {'cover-card': id === cover_image_id})}>
                  <img className="card-img-top listing-card-image" style={{
                    backgroundImage: url ? `url(${url})` : "", opacity: uploading ? '0.2' : '1'
                  }}/>
                  {uploading ? <div className="uploading-notice">Processing image</div> : ''}
                  <div className="row">
                    <div className="col-6">
                      {id === cover_image_id ? (
                        <button
                          disabled={!modalCoverImage}
                          className={classnames("file-action-button", {"loading-ellipsis": !modalCoverImage})}
                          onClick={preventDefault(toggleCropModal)}
                        >
                          Crop Image
                        </button>
                      ) : (
                        <button
                          className="file-action-button"
                          disabled={uploading}
                          onClick={preventDefault(updateField, 'cover_image_id', id)}
                        >
                          Set Thumbnail
                        </button>
                      )}
                    </div>
                    <div className="col-6">
                      <button
                        className="file-action-button"
                        disabled={uploading || lastPublishedPhoto}
                        onClick={preventDefault(removeMedia, id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>
      </div>
      {displayCropModal && imagePresence && (
        <CropModal
          croppedImage={coverImage}
          uncroppedImage={modalCoverImage}
          updateCroppedImage={updateCroppedImage}
          toggle={toggleCropModal}
        />
      )}
    </div>
  )
}
